$s05: 5px;
$s10: 10px;
$s15: 15px;
$s20: 20px;
$s25: 25px;
$s30: 30px;
$s40: 40px;
$s50: 50px;
$s60: 60px;
$s70: 70px;
$s80: 80px;
$s90: 90px;
$s100: 100px;
$s120: 120px;
$s150: 150px;
$s200: 200px;
$s250: 250px;
$s300: 300px;
$s350: 350px;
$s400: 400px;
$s450: 450px;
$s500: 500px;
$s550: 550px;
$s600: 600px;
$s650: 650px;
$s700: 700px;
$s750: 750px;
$s800: 800px;
$s850: 850px;
$s900: 900px;
$s950: 950px;
$s1000: 1000px;

.p-1 { padding: $s05; }
.p-2 { padding: $s10; }
.p-3 { padding: $s15; }
.p-4 { padding: $s20; }
.p-5 { padding: $s25; }
.p-6 { padding: $s30; }

.px-1 { padding: 0px $s05; }
.px-2 { padding: 0px $s10; }
.px-3 { padding: 0px $s15; }
.px-4 { padding: 0px $s20; }
.px-5 { padding: 0px $s25; }
.px-6 { padding: 0px $s30; }

.py-1 { padding: $s05 0px; }
.py-2 { padding: $s10 0px; }
.py-3 { padding: $s15 0px; }
.py-4 { padding: $s20 0px; }
.py-5 { padding: $s25 0px; }
.py-6 { padding: $s30 0px; }

.pt-1 { padding-top: $s05; }
.pt-2 { padding-top: $s10; }
.pt-3 { padding-top: $s15; }
.pt-4 { padding-top: $s20; }
.pt-5 { padding-top: $s25; }
.pt-6 { padding-top: $s30; }

.pr-1 { padding-right: $s05; }
.pr-2 { padding-right: $s10; }
.pr-3 { padding-right: $s15; }
.pr-4 { padding-right: $s20; }
.pr-5 { padding-right: $s25; }
.pr-6 { padding-right: $s30; }
.pr-10 { padding-right: $s50; }

.pb-1 { padding-bottom: $s05; }
.pb-2 { padding-bottom: $s10; }
.pb-3 { padding-bottom: $s15; }
.pb-4 { padding-bottom: $s20; }
.pb-5 { padding-bottom: $s25; }
.pb-6 { padding-bottom: $s30; }

.pl-1 { padding-left: $s05; }
.pl-2 { padding-left: $s10; }
.pl-3 { padding-left: $s15; }
.pl-4 { padding-left: $s20; }
.pl-5 { padding-left: $s25; }
.pl-6 { padding-left: $s30; }

.m-1 { margin: $s05; }
.m-2 { margin: $s10; }
.m-3 { margin: $s15; }
.m-4 { margin: $s20; }
.m-5 { margin: $s25; }
.m-6 { margin: $s30; }

.mx-1 { margin: 0px $s05; }
.mx-2 { margin: 0px $s10; }
.mx-3 { margin: 0px $s15; }
.mx-4 { margin: 0px $s20; }
.mx-5 { margin: 0px $s25; }
.mx-6 { margin: 0px $s30; }

.my-1 { margin: $s05 0px; }
.my-2 { margin: $s10 0px; }
.my-3 { margin: $s15 0px; }
.my-4 { margin: $s20 0px; }
.my-5 { margin: $s25 0px; }
.my-6 { margin: $s30 0px; }

.mt-1 { margin-top: $s05; }
.mt-2 { margin-top: $s10; }
.mt-3 { margin-top: $s15; }
.mt-4 { margin-top: $s20; }
.mt-5 { margin-top: $s25; }
.mt-6 { margin-top: $s30; }

.mr-1 { margin-right: $s05; }
.mr-2 { margin-right: $s10; }
.mr-3 { margin-right: $s15; }
.mr-4 { margin-right: $s20; }
.mr-5 { margin-right: $s25; }
.mr-6 { margin-right: $s30; }

.mb-1 { margin-bottom: $s05; }
.mb-2 { margin-bottom: $s10; }
.mb-3 { margin-bottom: $s15; }
.mb-4 { margin-bottom: $s20; }
.mb-5 { margin-bottom: $s25; }
.mb-6 { margin-bottom: $s30; }

.ml-1 { margin-left: $s05; }
.ml-2 { margin-left: $s10; }
.ml-3 { margin-left: $s15; }
.ml-4 { margin-left: $s20; }
.ml-5 { margin-left: $s25; }
.ml-6 { margin-left: $s30; }

.w-10 { width: $s10; }
.w-20 { max-width: $s20; min-width: $s20; }
.w-30 { width: $s30; }
.w-40 { width: $s40; }
.w-50 { width: $s50; }
.w-80 { width: $s80; }
.w-100 { width: $s100; }
.w-150 { width: $s150; }
.w-200 { width: $s200; }
.w-300 { width: $s300; }
.w-400 { width: $s400; }
.w-500 { width: $s500; }
.w-600 { width: $s600; }
.w-700 { width: $s700; }
.w-800 { width: $s800; }
.w-900 { width: $s900; }
.w-1000 { width: $s1000; }
.w-full { width: 100%; }
.w-95p { width: 95%; }
.w-90p { width: 90%; }
.w-80p { width: 80%; }

.h-10 { height: $s10; }
.h-20 { height: $s20; }
.h-50 { height: $s50; }
.h-60 { height: $s60; }
.h-70 { height: $s70; }
.h-80 { height: $s80; }
.h-90 { height: $s90; }
.h-100 { height: $s100; }
.h-150 { height: $s150; }
.h-200 { height: $s200; }
.h-300 { height: $s300; }
.h-400 { height: $s400; }
.h-500 { height: $s500; }
.h-600 { height: $s600; }
.h-700 { height: $s700; }
.h-800 { height: $s800; }
.h-900 { height: $s900; }
.h-1000 { height: $s1000; }
.h-full { height: 100%; }

.mw-40 { max-width: $s40; }
.nw-40 { min-width: $s40; }
.mw-60 { max-width: $s60; }
.nw-60 { min-width: $s60; }
.mw-80 { max-width: $s80; }
.nw-80 { min-width: $s80; }
.mw-100 { max-width: $s100; }
.nw-100 { min-width: $s100; }
.mw-120 { max-width: $s120; }
.nw-120 { min-width: $s120; }
.mw-150 { max-width: $s150; }
.nw-150 { min-width: $s150; }
.nw-250 { min-width: $s250; }
.mw-200 { max-width: $s200; }
.nw-200 { min-width: $s200; }
.mw-300 { max-width: $s300; }
.nw-300 { min-width: $s300; }
.mw-350 { max-width: $s350; }
.nw-350 { min-width: $s350; }
.mw-500 { max-width: $s500; }
.nw-500 { min-width: $s500; }
.mw-1000 { max-width: $s1000; }
.nw-1000 { min-width: $s1000; }

.mh-20 { max-height: $s20; }
.nh-20 { min-height: $s20; }
.nh-40 { min-height: $s40; }
.nh-80 { min-height: $s80; }
.nh-100 { min-height: $s100; }
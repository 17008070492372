@import '_colors';

.main {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    max-height: 100vh;
    height: 100vh;
    overflow: hidden;
}

.flex { flex: auto; }
.flex-1 { flex: 1; }
.flex-2 { flex: 2; }
.flex-3 { flex: 3; }
.flex-4 { flex: 4; }
.flex-5 { flex: 5; }

.podcasts-script-upload {
    background-color: $white;
    color: $black;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    min-height: 100vh;
    width: 100%;
}

.podcasts {
    background-color: $blue_gray;
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    min-height: 100vh;
    width: 100%;

    .podcast_item {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2.5rem;
        margin: 0.5rem;
    }


    .podcast_slider {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

}

.header {
    background-color: $dark_blue;
    flex: 0 0 45px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: $light_gray;
    z-index: 1000 !important;
    &__center {
        display: flex;
    }

    &__left {
        flex: 1;
        text-align: left;
        padding-left: 20px;
        &__icon {
            margin-right: 20px;
        }
    }

    &__right {
        flex: 1;
        text-align: right;
        padding-right: 20px;
    }
}

.content {
    flex: 1 1 auto;
    overflow: auto;
}

.footer {
    flex: 0 0 50px;
    background-color: $dark_blue;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    color: $light_gray;

    &__item {
        width: 100%;
        height: 50px;
        flex: 0 0 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &__label {
            font-size: 10px;
        }
        &__selected {
            @extend .footer__item;
            background-color: $silver;
            color: $dark_blue;
        }
    }
}

.footer__item__ios {
    @extend .footer__item;
    justify-content: flex-start;
    padding-top: 5px;
}

.footer__item__selected__ios {
    @extend .footer__item__ios;
    background-color: $silver;
    color: $dark_blue;
}

.login-button {
    position: fixed;
    top: 50%;
    display: flex;
}

.welcolm-message {
    position: fixed;
    top: 30%;
    display: flex;
}

.container {
    padding: 1vh 1vw;
}

.highcharts-credits {
    display: none;
}

.fixed {
    position: fixed;
    z-index: 1000 !important;
    bottom: 82px;
}


.row {
    display: flex;
    align-items: center;
    &-center-start {
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }
    &-center {
        @extend .row;
        justify-content: center;
    }
    &-left {
        @extend .row;
        justify-content: flex-start;
    }
    &-right {
        @extend .row;
        justify-content: flex-end;
    }
    &-between {
        @extend .row;
        justify-content: space-between;
    }
    &-around {
        @extend .row;
        justify-content: space-around;
    }
    &-stretch {
        @extend .row;
        align-items: stretch;
        justify-content: flex-start;
    }
}

.column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    &-center {
        @extend .column;
        align-items: center;
    }
    &-left {
        @extend .column;
        align-items: flex-start;
    }
    &-right {
        @extend .column;
        align-items: flex-end;
    }
    &-between {
        @extend .column;
        align-items: space-between;
    }
}

.center { text-align: center; }

.modal__geo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    &__row {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 20px;
        margin-right: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__label {
            width: 50%;
        }
        &__menu {
            width: 50%;
        }
    }
    &__btn {
        margin-top: 30px;
    }
}

.paragraph {
    max-width: 90vw;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
}

.box-shadow-0 {
    box-shadow: none !important;
}

.cell-liner {
    padding: 0px;
    height: 15px;
    position: relative;
    top: -5px;
}

.upload-card {
    width: 100px;
    height: 100px;
}

.edge {
    padding-left: 2px !important;
    padding-right: 2px !important;
}
.scroll-country-profile {
    scroll-margin-top: 152px;
}
.scroll-company-profile {
    scroll-margin-top: 152px;
}
.starter {
    justify-self: center;
}
.up-3 {
    position: relative;
    top: -3px;
}
.up-40 {
    position: relative;
    top: -40px;
}
.z999 {
    position: relative;
    z-index: 999;
}

.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}
.text-center {
    text-align: center;
}

.rotate {
    
    transform: rotate(-90deg);
  
    /* Legacy vendor prefixes that you probably don't need... */
  
    /* Safari */
    -webkit-transform: rotate(-90deg);
  
    /* Firefox */
    -moz-transform: rotate(-90deg);
  
    /* IE */
    -ms-transform: rotate(-90deg);
  
    /* Opera */
    -o-transform: rotate(-90deg);
  
    /* Internet Explorer */
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
.nowrap {
    white-space: nowrap;
}
.avatar {
    vertical-align: middle;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.rounded_corner {
    border-radius: 3px;
}
$size-01: 12px;
$size-02: 14px;
$size-03: 16px;
$size-04: 18px;
$size-05: 20px;
$size-06: 22px;
$size-07: 24px;
$size-08: 26px;
$size-09: 28px;
$size-10: 30px;
$size-11: 32px;
$size-12: 34px;

$px-14: 14px;
$px-16: 16px;
$px-17: 17px;
$px-20: 20px;
$px-30: 30px;

$bold-1: 100;
$bold-2: 200;
$bold-3: 300;
$bold-4: 400;
$bold-5: 500;
$bold-6: 600;
$bold-7: 700;
$bold-8: 800;
$bold-9: 900;

.f-01 { font-size: $size-01; }
.f-02 { font-size: $size-02; }
.f-03 { font-size: $size-03; }
.f-04 { font-size: $size-04; }
.f-05 { font-size: $size-05; }
.f-06 { font-size: $size-06; }
.f-07 { font-size: $size-07; }
.f-08 { font-size: $size-08; }
.f-09 { font-size: $size-09; }
.f-10 { font-size: $size-10; }
.f-11 { font-size: $size-11; }
.f-12 { font-size: $size-12; }

.fs-14 { font-size: $px-14; }
.fs-16 { font-size: $px-16; }
.fs-17 { font-size: $px-17; }
.fs-20 { font-size: $px-20; }
.fs-30 { font-size: $px-30; }

.bold { font-weight: $bold-5; }
.bolder { font-weight: $bold-7; }

.bold-1 { font-weight: $bold-1; }
.bold-2 { font-weight: $bold-2; }
.bold-3 { font-weight: $bold-3; }
.bold-4 { font-weight: $bold-4; }
.bold-5 { font-weight: $bold-5; }
.bold-6 { font-weight: $bold-6; }
.bold-7 { font-weight: $bold-7; }
.bold-8 { font-weight: $bold-8; }
.bold-9 { font-weight: $bold-9; }

.underline { text-decoration: underline; }
.no-decoration { text-decoration: none; }

.title {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 500;
}
.subtitle {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 500;
}

.ft-title {
    font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
}

.ft-tweet {
    font-family: Helvetica, Arial, "Segoe UI";
}

.t-wrap {
    text-overflow: unset;
}
.t-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.ft-lato {
    font-family: 'Lato', sans-serif;
}

.line-height-news {
    line-height: 1.3;
}

@font-face {
    font-family: 'poynter bold';
    src: local('poynter bold'), url('./poynter bold.woff') format('woff');
}
.ft-news-title {
    @extend .fs-20;
    font-family: "poynter bold", sans-serif;
}

// @font-face {
//     font-family: 'publico text roman';
//     src: local('publico text roman'), url('./Publico Text-Roman.ttf') format('truetype');
// }

@font-face {
    font-family: 'publico text roman';
    src: local('publico text roman'), url('publico text roman.otf') format('opentype');
}
.ft-news {
    @extend .fs-17;
    font-family: "publico text roman", sans-serif;
}

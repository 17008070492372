.home {
    display: block;
    overflow: auto;
    &__title {
        text-align: center;
        font-size: 36px;
    }
    &__text {
        text-align: center;
        font-size: 16px;
        margin-top: 10%;
        margin-left: 5%;
        margin-right: 5%;
    }
}

.border-1 {
    border: 1px solid black;
    border-collapse: collapse;
}

.border-1-grey {
    border: 1px solid $light_gray;
    border-collapse: collapse;
}

.dot {
    height: 7px;
    width: 7px;
    background-color: $dark_gray;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
    padding: 1px;
}

.space {
    height: 7px;
    width: 7px;
    margin-right: 5px;
}
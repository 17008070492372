.chevron {
    color: $light_gray;
    margin-left: 5px;
    &__down {
        margin-left: 5px;
    }
}

.sort {
    color: rgba(0, 0, 0, 0.54);
    margin-left: 5px;
    &__down {
        margin-left: 5px;
    }
}

.breadcrumb {
    margin-top: .5em;
    margin-bottom: 2em;
    margin-left: 2em;
    margin-right: 2em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    &__content {
        position: absolute;
        width: 80%;
        height: 80%;
        background-color: $light_gray;
        border: 2px solid #000;
        padding: 5px;
    }
    &__curve {
        position: absolute;
        width: 80%;
        height: 80%;
        background-color: $white;
        border: 2px solid #000;
        padding: 5px;
    }
}

.yearRangeSelector {
    display: flex;
    width: 300px;
    align-items: center;
    justify-content: center;
    &__label {
        padding-top: 14px;
        padding-bottom: 13px;
    }
    &__slider {
        margin-left: 10px;
        width: 100px;
    }
}

.searchbar {
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    &__left {
        flex: 5;
    }

    &__right {
        flex: 1;
    }
}

.MuiInputLabel-outlined {
    z-index: 0 !important;
}

.bg-teal_lighter .Mui-selected {
    background-color: #ffffff !important;
    color: $dark_gray !important;
}
.containerSpinner {
    width: 200px;
    height: 200px;
    position: relative;
    margin: 30px;
   
}

.spinnerActual {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    border:5px solid #ccc;
    border-radius: 50%;
    
}

.overlay {
    z-index: 0;
    animation:rt 2s infinite;
    border-top:5px solid #33478e;
    background-color: transparent;

}






.spinner {
    position: fixed;
    background-color: #fff;
    width:200px;
    height:200px;
    border-radius:50%;
    border:5px solid #ccc;
    z-index:1;
    &:before {
        @extend .spinner;
        content: "";
        border-top:5px solid #33478e;
        z-index:0;
        animation:rt 2s infinite;
    }
}

@keyframes rt {
    from{
        transform:rotate(0deg);
    }
    to{
        transform:rotate(360deg);
    }
}

.spinnerActual > .fa {
    font-size:32px;
    display:block;
}


.centralize {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.jump {
    animation: MoveUpDown 2s linear infinite;
    position: absolute;
    bottom: 55px;
    width: 100%;
    
}
  
@keyframes MoveUpDown {
    0%, 100% {
        bottom: 55px;
    }
    50% {
        bottom: 65px;
    }
}
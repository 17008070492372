#qr_code {
    border: 3px solid black;
}

.heading {
    text-transform: uppercase;
}

.digital_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem;
    padding: 0;
}

.generateBtn {
  width: 130px;
  height: 35px;
  background: rgb(73, 173, 48);
  border: none;
  border-radius: 0.3rem;
  vertical-align: top;
  margin: 0.2rem;
  cursor: pointer;
  color: #fff;
}

.generateBtn:disabled {
    background: #999;
    color: #555;
    cursor: not-allowed;
  }

.clearBtn {
  width: 130px;
  height: 35px;
  background: rgb(255, 98, 98);
  border: none;
  border-radius: 0.3rem;
  vertical-align: top;
  margin: 0.2rem;
  cursor: pointer;
  color: #fff;
}

.initial {
    width: 100%;
    font-family: 'Lato', sans-serif;
    font-size: 7px;
    text-align: center;
}

.qr_form {
    width: 100%;
}
.section {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
}
.field {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin: 0.5rem 0 0.5rem 0.5rem;
}

